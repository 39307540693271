// React and Router Dependencies
import React, { useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

// Custom Hooks
import useTeachers from "../../../data/hooks/users/useTeachers";
import useRolesRole from "../../../data/hooks/useRolesRole";

// Components - Table Related
import BasicTable from "../../../Components/Table/BasicTable";
import TableButton from "../../../Components/Table/TableButton";
import EditButton from "../../../Components/Table/EditButton";
import DeleteButton from "../../../Components/Table/DeleteButton";

// Components - Forms and Modals
import Modal from "../../../Components/Modals/Modal";
import CreateEditForm from "../../../Components/Forms/CreateEditForm";
import ConfirmationPopup from "../../../Components/Modals/ConfirmationPopup";
import ErrorDisplay from "../../../Components/Error/ErrorDisplay";

// Services and Repositories
import UsersRepository from "../../../data/repository/UsersRepository";
import UsersService from "../../../data/services/UsersService";
import { verifyUserEmail } from "../../../service/FirebaseFunctions";

// Utils and Helpers
import { TeacherFormSchema } from "../../../utils/validationSchema";
import { mapperTeacherData } from "../../../Mapper/TeacherMapper";
import teacherInputs from "../../../Components/Inputs/TeacherInputs";
import { ToastMessage } from "../../../Components/Toast/ToastMessage";

// Assets
import { ReactComponent as AddUserIcon } from "../../../assets/images/icons/add-user.svg";

function TeachersPage(props) {
  const history = useHistory();
  const { school: schoolId = props.schoolId } = useParams();

  const initialState = { add: false, edit: false, delete: false };
  const [modalState, setModalState] = useState(initialState);
  const [selectedTeacher, setSelectedTeacher] = useState(null);

  const { isTeachersLoading, teachersError, teachersData, setData } = useTeachers(schoolId);
  const { sectionsLabels, teachers, sections } = teachersData ?? {};
  const { rolesRoleData } = useRolesRole("teacher");

  const usersRepository = new UsersRepository();
  const usersService = new UsersService(usersRepository);

  const handleAddNewTeacher = async (data) => {
    const toastId = ToastMessage.loading("انتظر من فضلك...");
    try {
      const userExists = await verifyUserEmail({ email: data.email });
      if (userExists) {
        ToastMessage.update(toastId, "error", "البريد الاكتروني مستخدم");
        return;
      }
      setModalState({ ...modalState, add: false });

      const params = { ...data, school: schoolId, role: rolesRoleData };
      const sections = params?.sections?.map((section) => section.value);
      const userData = {
        firstName: params.firstName,
        lastName: params.lastName,
        email: params.email,
        role: params.role,
        school: params.school,
        sections: sections,
        status: "active",
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      const newUser = await usersService.addUserParams(userData);
      const newDataUser = mapperTeacherData(newUser, sections, sectionsLabels);

      setData((prevState) => ({
        ...prevState,
        teachers: [newDataUser, ...prevState.teachers],
      }));
      ToastMessage.update(toastId, "success", "تم إضافة المعلم بنجاح");
    } catch (error) {
      console.error("Error adding new Teacher:", error);
      ToastMessage.update(toastId, "error", "حدث خطأ أثناء إضافة المعلم");
    }
  };

  const handleEditTeacher = async (data) => {
    const toastId = ToastMessage.loading("انتظر من فضلك...");
    const currentEmail = selectedTeacher.email;
    try {
      const userExists = await verifyUserEmail({ email: data.email });
      if (userExists && currentEmail !== data.email) {
        ToastMessage.update(toastId, "error", "البريد الاكتروني مستخدم");
        return;
      }
      setModalState({ ...modalState, edit: false });
      const teacherId = selectedTeacher._id;
      const sections = data?.sections?.map((section) => section.value);

      const params = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        role: data.role,
        school: data.school,
        sections: sections,
        currentEmail: currentEmail,
        status: "active",
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      const updatedData = await usersService.updateUser(teacherId, params);
      const userData = { ...selectedTeacher, ...updatedData };
      const newData = mapperTeacherData(userData, sections, sectionsLabels);

      const updatedTeachers = teachers.map((teacher) =>
        teacher.id === teacherId ? { ...teacher, ...newData } : teacher
      );

      setData((prevState) => ({ ...prevState, teachers: updatedTeachers }));
      ToastMessage.update(toastId, "success", "تم تعديل المعلم بنجاح");
    } catch (error) {
      console.error("Error updating teacher:", error);
      ToastMessage.update(toastId, "error", "حدث خطأ أثناء تعديل المعلم");
    }
  };

  const handleDeleteTeacher = async () => {
    const toastId = ToastMessage.loading("انتظر من فضلك...");
    setModalState({ ...modalState, delete: false });
    try {
      const teacherId = selectedTeacher._id;
      await usersService.deleteUserById(teacherId);
      const newData = teachers.filter((teacher) => teacher.id !== teacherId);

      setData((prevState) => ({ ...prevState, teachers: newData }));
      ToastMessage.update(toastId, "success", "تم حذف المعلم بنجاح");
    } catch (error) {
      console.error("Error deleting school:", error);
      ToastMessage.update(toastId, "error", "حدث خطأ أثناء حذف المعلم");
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "اسم المعلم",
        accessorFn: (row) => `${row?.firstName || ""} ${row?.lastName || ""}`,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "البريد الالكتروني",
        accessorFn: (row) => row?.email || "-",
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "عدد الصفوف",
        accessorFn: (row) => row?.data?.numberOfClasses || 0,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "عدد الشعب",
        accessorFn: (row) => row?.data?.numberOfSections || 0,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "عدد الطلاب",
        accessorFn: (row) => row?.data?.numberOfStudents || 0,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "",
        accessorKey: "actions",
        enableSorting: false,
        cell: (props) => (
          <div className="d-flex align-items-center gap-2">
            <EditButton
              className="table-action-buttons"
              onClick={() => {
                setSelectedTeacher(props.row.original);
                setModalState({ ...modalState, edit: true });
              }}
            />
            <DeleteButton
              className="table-action-buttons"
              onClick={() => {
                setSelectedTeacher(props.row.original);
                setModalState({ ...modalState, delete: true });
              }}
            />
          </div>
        ),
      },
    ],
    []
  );

  const tdClickHandler = (e, cell) => {
    if (cell.column.id !== "actions") {
      history.push(`/schools/${schoolId}/teacher/${cell.row.original.id}`);
    }
  };

  if (teachersError) {
    return <ErrorDisplay userMessage={"Error while fetching Teachers"} errorMessage={teachersError} />;
  }

  return (
    <React.Fragment>
      <BasicTable
        columns={columns}
        data={teachers || []}
        handleTdClick={tdClickHandler}
        divClass="container-table mt-4 mb-5"
        tableClass="basic-table row-full-width"
        messageNotFoundResult={"لا يوجد معلمين"}
        isLoading={isTeachersLoading}
        isGlobalFilter={true}
        header={
          <TableButton
            text={"إضافة معلم"}
            icon={<AddUserIcon width={16} height={16} fill="#fff" />}
            onClick={() => setModalState({ ...modalState, add: true })}
          />
        }
      />

      <Modal
        open={modalState.add}
        close={() => setModalState({ ...modalState, add: false })}
        title={"إضافة معلم"}
      >
        <CreateEditForm
          schemaType={TeacherFormSchema}
          onCancelClick={() => setModalState({ ...modalState, add: false })}
          onSubmit={handleAddNewTeacher}
          inputFields={teacherInputs(modalState, sectionsLabels)}
        />
      </Modal>

      <Modal
        open={modalState.edit}
        close={() => setModalState({ ...modalState, edit: false })}
        title={"تعديل بيانات المعلم"}
      >
        <CreateEditForm
          onSubmit={handleEditTeacher}
          schemaType={TeacherFormSchema}
          values={selectedTeacher}
          onCancelClick={() => setModalState({ ...modalState, edit: false })}
          inputFields={teacherInputs(modalState, sectionsLabels)}
        />
      </Modal>

      <ConfirmationPopup
        open={modalState.delete}
        close={() => setModalState({ ...modalState, delete: false })}
        onConfirm={handleDeleteTeacher}
        title={"حذف المعلم"}
        subtitle={`${selectedTeacher?.firstName} ${selectedTeacher?.lastName}`}
      />
    </React.Fragment>
  );
}

export default TeachersPage;
