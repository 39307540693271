import React, { useMemo, useState } from "react";
import BasicTable from "../../../Components/Table/BasicTable";
import TableButton from "../../../Components/Table/TableButton";
import { ReactComponent as AdminIcon } from "../../../assets/images/icons/admin.svg";
import EditButton from "../../../Components/Table/EditButton";
import DeleteButton from "../../../Components/Table/DeleteButton";
import ConfirmationPopup from "../../../Components/Modals/ConfirmationPopup";
import { SchoolAdminFormSchema } from "../../../utils/validationSchema";
import Modal from "../../../Components/Modals/Modal";
import CreateEditForm from "../../../Components/Forms/CreateEditForm";
import { useParams } from "react-router-dom";
import ErrorDisplay from "../../../Components/Error/ErrorDisplay";
import UsersRepository from "../../../data/repository/UsersRepository";
import UsersService from "../../../data/services/UsersService";
import { ToastMessage } from "../../../Components/Toast/ToastMessage";
import schoolAdminInputs from "../../../Components/Inputs/schoolAdminInputs";
import useSchoolAdmin from "../../../data/hooks/users/useSchoolAdmin";
import useRolesRole from "../../../data/hooks/useRolesRole";
import { verifyUserEmail } from "../../../service/FirebaseFunctions";

function SchoolAdmin(props) {
  const { school: schoolId = props.schoolId } = useParams();
  const { rolesRoleData } = useRolesRole("schooladmin");

  const initialState = { add: false, edit: false, delete: false };
  const [modalState, setModalState] = useState(initialState);
  const [selectedAdmin, setSelectedAdmin] = useState(null);

  const { isSchoolAdminLoading, schoolAdminError, schoolAdminData, setData } =
    useSchoolAdmin(schoolId);

  const usersRepository = new UsersRepository();
  const usersService = new UsersService(usersRepository);

  const AddNewAdmin = async (data) => {
    const toastId = ToastMessage.loading("انتظر من فضلك...");

    try {
      const userExists = await verifyUserEmail({ email: data.email });
      if (userExists) {
        ToastMessage.update(toastId, "error", "البريد الاكتروني مستخدم");
        return;
      }
      setModalState({ ...modalState, add: false });
      const params = { ...data, school: schoolId, role: rolesRoleData };

      const userData = {
        firstName: params.firstName,
        lastName: params.lastName,
        email: params.email,
        password: params.password,
        role: params.role,
        school: params.school,
        status: "active",
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      const newUser = await usersService.addUserParams(userData);
      setData((prev) => [newUser, ...prev]);
      ToastMessage.update(toastId, "success", "تم إضافة المسؤول بنجاح");
    } catch (error) {
      console.error("Error adding new Teacher:", error);
      ToastMessage.update(toastId, "error", "حدث خطأ أثناء إضافة المسؤول");
    }
  };

  const editAdmin = async (data) => {
    const toastId = ToastMessage.loading("انتظر من فضلك...");
    const currentEmail = selectedAdmin.email;
    try {
      const userExists = await verifyUserEmail({ email: data.email });
      if (userExists && currentEmail !== data.email) {
        ToastMessage.update(toastId, "error", "البريد الاكتروني مستخدم");
        return;
      }
      setModalState({ ...modalState, edit: false });
      const userId = selectedAdmin.id;
      const params = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        role: data.role,
        school: data.school,
        currentEmail: currentEmail,
        status: "active",
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      const updatedData = await usersService.updateUser(userId, params);
      setData((prev) =>
        prev.map((admin) => (admin.id === userId ? updatedData : admin))
      );

      ToastMessage.update(toastId, "success", "تم تعديل بيانات المسؤول بنجاح");
    } catch (error) {
      console.error("Error updating teacher:", error);
      ToastMessage.update(
        toastId,
        "error",
        "حدث خطأ أثناء تعديل بيانات المسؤول"
      );
    }
  };

  const deleteAmin = async () => {
    const toastId = ToastMessage.loading("انتظر من فضلك...");
    setModalState({ ...modalState, delete: false });
    try {
      const userId = selectedAdmin.id;
      await usersService.deleteUserById(userId);
      setData((prev) => prev.filter((user) => user.id !== userId));
      ToastMessage.update(toastId, "success", "تم حذف المسؤول بنجاح");
    } catch (error) {
      console.error("Error deleting school:", error);
      ToastMessage.update(toastId, "error", "حدث خطأ أثناء حذف المسؤول");
    }
  };
  const columns = useMemo(
    () => [
      {
        header: "اسم المسؤول",
        accessorFn: (row) => `${row?.firstName || ""} ${row?.lastName || ""}`,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "البريد الالكتروني",
        accessorFn: (row) => row?.email || "-",
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "",
        accessorKey: "actions",
        enableSorting: false,
        cell: (props) => (
          <div className="d-flex align-items-center gap-2">
            <EditButton
              className="table-action-buttons"
              onClick={() => {
                setSelectedAdmin(props.row.original);
                setModalState({ ...modalState, edit: true });
              }}
            />
            <DeleteButton
              className="table-action-buttons"
              onClick={() => {
                setSelectedAdmin(props.row.original);
                setModalState({ ...modalState, delete: true });
              }}
            />
          </div>
        ),
      },
    ],
    []
  );

  if (schoolAdminError) {
    return (
      <ErrorDisplay
        userMessage={"Error while fetching Teachers"}
        errorMessage={schoolAdminError}
      />
    );
  }

  return (
    <React.Fragment>
      <BasicTable
        columns={columns}
        data={schoolAdminData || []}
        handleTdClick={() => {}}
        divClass="container-table mt-4 mb-5"
        tableClass="basic-table row-full-width"
        messageNotFoundResult={"لا يوجد مسؤولين"}
        isLoading={isSchoolAdminLoading}
        isGlobalFilter={true}
        header={
          <TableButton
            text={"إضافة مسؤول"}
            icon={<AdminIcon width={23} height={23} fill="#fff" />}
            onClick={() => setModalState({ ...modalState, add: true })}
          />
        }
      />

      {/* ---------- Add Teacher ---------- */}
      <Modal
        open={modalState.add}
        close={() => setModalState({ ...modalState, add: false })}
        title={"إضافة مسؤول"}
      >
        <CreateEditForm
          schemaType={SchoolAdminFormSchema}
          onCancelClick={() => setModalState({ ...modalState, add: false })}
          onSubmit={AddNewAdmin}
          inputFields={schoolAdminInputs(modalState)}
        />
      </Modal>

      {/* ---------- Edit Teacher ---------- */}
      <Modal
        open={modalState.edit}
        close={() => setModalState({ ...modalState, edit: false })}
        title={"تعديل بيانات المسؤول"}
      >
        <CreateEditForm
          onSubmit={editAdmin}
          schemaType={SchoolAdminFormSchema}
          values={selectedAdmin}
          onCancelClick={() => setModalState({ ...modalState, edit: false })}
          inputFields={schoolAdminInputs(modalState)}
        />
      </Modal>

      {/* ---------- Delete Teacher ---------- */}
      <ConfirmationPopup
        open={modalState.delete}
        close={() => setModalState({ ...modalState, delete: false })}
        onConfirm={deleteAmin}
        title={"حذف المسؤول"}
        subtitle={`${selectedAdmin?.firstName} ${selectedAdmin?.lastName}`}
      />
    </React.Fragment>
  );
}

export default SchoolAdmin;
