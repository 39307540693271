import { createContext, useContext, useState, useEffect } from "react";
import BackendService from "../service/BackendService";
import UsersRepository from "../data/repository/UsersRepository";
import UsersService from "../data/services/UsersService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getFirebaseService } from "../service/FirebaseAuthService";
import FirebaseStorageService from "../service/FirebaseStorageService";
import Loading from "../Components/Loading/Loading";

const StoreContext = createContext();

export function DataProvider({ children }) {
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const backendService = new BackendService();

  useEffect(() => {
    const auth = getFirebaseService().auth;
    const accessToken = backendService.getAccessToken();

    const handleAuthStateChanged = async (firebaseUser) => {
      if (!firebaseUser && !accessToken) {
        history.replace("/login");
      } else {
        try {
          const uid = firebaseUser.uid;
          const credentials = {
            uid: uid,
            secret: process.env.REACT_APP_KAT_BACKEND_SECRET_KEY,
          };
          await backendService.requestAccessToken(credentials);
          const usersRepository = new UsersRepository();
          const usersService = new UsersService(usersRepository);
          const user = await usersService.getUserByFilter({ uid: uid });
          //await usersService.updateLastLogin(user.id);
          const storageService = new FirebaseStorageService();
          const imageLink = await storageService.getDownloadURL(user?.photoUrl);
          setCurrentUser({ ...user, imageLink });
        } catch (error) {
          console.error("Failed to fetch user data:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    const unsubscribe = auth.onAuthStateChanged(handleAuthStateChanged);

    return () => unsubscribe();
  }, [history]);

  if (loading) {
    return <Loading />;
  }

  return (
    <StoreContext.Provider value={{ currentUser }}>
      {children}
    </StoreContext.Provider>
  );
}

export function useData() {
  return useContext(StoreContext);
}
